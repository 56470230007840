import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import DateRangePicker from "components/DashboardComponents/DayPicker/DayPicker";
import CustomFilter from "components/CustomFilter/CustomFilter";
import StatusLabel from "components/StatusLabel/StatusLabel";
import { formatCPF, formatCNPJ, formatPhone } from "utils/formatters";
import "./CustomDataTable.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const CustomDataTable = ({
  data,
  columns,
  title,
  showInfoColumn,
  infoButtonTitle,
  onInfoButtonClick,
  showWhatsappButton, // Prop opcional para exibir o botão de WhatsApp
  onWhatsappClick,
  filtersConfig,
  showStatusColumn,
  statusField,
  getStatusColor,
  getStatusText,
  totalRecords,
  loading,
  page,
  rows,
  onPageChange,
  onDateRangeChange,
  onSearch,
  searchPlaceholder = "Keyword",
}) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedRange, setSelectedRange] = useState(null);

  const handleDateRangeChange = (range) => {
    setSelectedRange(range);
    onDateRangeChange(range);
  };

  const handleSearchChange = (e) => {
    setGlobalFilter(e.target.value);
    onSearch(e.target.value);
  };

  const handleFilterChange = (newFilters) => {
    // Ações relacionadas a filtros podem ser adicionadas aqui se necessário
  };

  const renderHeader = () => {
    return (
      <div className="table-header">
        <h2 className="table-title">{title}</h2>
        <div className="table-filters">
          <DateRangePicker
            selectedRange={selectedRange}
            setSelectedRange={handleDateRangeChange}
          />
          <CustomFilter
            filtersConfig={filtersConfig}
            onFilterChange={handleFilterChange}
          />
          <div className="p-inputgroup flex-1">
            <Button label="Buscar" />
            <InputText
              placeholder={searchPlaceholder}
              value={globalFilter}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const infoColumnBodyTemplate = (rowData) => (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Button
        label={infoButtonTitle || "Ver mais"}
        onClick={() => onInfoButtonClick(rowData)}
        className="p-button-rounded p-button-outlined"
        style={{ marginRight: showWhatsappButton ? "4rem" : "0", borderRadius: "10px" }}
      />
      {showWhatsappButton && (
        <Button
          icon="pi pi-whatsapp"
          onClick={() => onWhatsappClick(rowData)}
          className="p-button-rounded p-button-outlined p-button-icon-only large-icon-button whatsapp-button"
          style={{
            color: "#25D366",
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
            padding: 0,
          }}
        />
      )}
    </div>
  );

  const statusColumnBodyTemplate = (rowData) => {
    const statusText = getStatusText(rowData[statusField]);
    const statusColor = getStatusColor ? getStatusColor(rowData[statusField]) : "#6C757D";
    return <StatusLabel text={statusText} color={statusColor} />;
  };

  const cpfCnpjColumnBodyTemplate = (rowData) => {
    const isIndividualClient = rowData.enTypeMerchant === "PF";
    return isIndividualClient ? formatCPF(rowData.cpfCnpj) : formatCNPJ(rowData.cpfCnpj);
  };

  const phoneColumnBodyTemplate = (rowData) => formatPhone(rowData.phoneNumber);

  return (
    <div className="custom-table-wrapper">
      <DataTable
        value={data}
        header={renderHeader()}
        className="custom-data-table"
        paginator={true}
        rows={rows}
        totalRecords={totalRecords}
        lazy={true}
        onPage={onPageChange}
        first={page * rows}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: "50rem" }}
        loading={loading}
      >
        {columns.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            bodyClassName={`column-body-${col.field}`}
            headerClassName={`column-header-${col.field}`}
            body={
              col.field === "cpfCnpj"
                ? cpfCnpjColumnBodyTemplate
                : col.field === "phoneNumber"
                ? phoneColumnBodyTemplate
                : undefined
            }
          />
        ))}

        {showStatusColumn && (
          <Column
            header="Status"
            body={statusColumnBodyTemplate}
            className="column-status"
            style={{ textAlign: "center" }}
          />
        )}

        {showInfoColumn && (
          <Column
            header="Informações"
            body={infoColumnBodyTemplate}
            className="column-info"
            style={{ textAlign: "center" }}
          />
        )}
      </DataTable>
    </div>
  );
};

export default CustomDataTable;
