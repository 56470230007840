import React from 'react';
import PropTypes from 'prop-types';
import './CustomInput.css';  // Importa o estilo associado ao componente

function CustomInput({
  label,
  type = 'text',
  value = '',
  name, 
  placeholder = '',
  onChange,
  onBlur,
  errorMessage = '',
  isRequired = false,
  className = '',
  readOnly = false,
  disabled = false,
  style = {}, // Adicionando a prop de estilo
  rightIcon = null, // Novo: ícone à direita
  onRightIconClick = null, // Novo: evento de clique no ícone
}) {
  return (
    <div className={`custom-input-container ${className}`} style={{ position: 'relative' }}>
      {label && (
        <label className="custom-input-label">
          {label} {isRequired && <span className="required">*</span>}
        </label>
      )}
      <input
        type={type}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        className={`custom-input-field ${errorMessage ? 'input-error' : ''}`}
        required={isRequired}
        readOnly={readOnly}
        disabled={disabled}
        style={style} // Aplicando o estilo inline ao input
      />
      {/* Ícone clicável à direita, se fornecido */}
      {rightIcon && (
        <i
          className={`custom-input-icon ${rightIcon}`}
          onClick={onRightIconClick}
          style={{
            position: 'absolute',
            right: '10px',
            top: '55%',
            cursor: 'pointer',
            background: 'var(--background-second-color)'
          }}
        />
      )}
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </div>
  );
}

// Validação das props
CustomInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  rightIcon: PropTypes.string, // Novo: validação para o ícone à direita
  onRightIconClick: PropTypes.func, // Novo: validação para o evento de clique do ícone
};

export default CustomInput;
