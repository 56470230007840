import React, { useState, useEffect, useCallback } from "react";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import { getWithdrawals } from "services/authService";
import "./AdminTransfers.css";

const AdminTransfers = () => {
  const [data, setData] = useState([]); // Dados da tabela
  const [page, setPage] = useState(0); // Página atual
  const [pageSize, setPageSize] = useState(10); // Tamanho da página
  const [totalRecords, setTotalRecords] = useState(0); // Total de registros
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [searchTerm, setSearchTerm] = useState(""); // Termo de busca

  // Função para buscar transferências
  const fetchWithdrawals = useCallback(async (page, pageSize, searchTerm = "") => {
    setLoading(true);
    try {
      const result = await getWithdrawals({
        page: page + 1, // O backend usa base 1
        pageSize,
        Term: searchTerm,
      });

      if (result && result.items && result.totalCount !== undefined) {
        // Filtrar os saques que não estão com status "Awaiting"
        const filteredData = result.items
          .filter((withdrawal) => withdrawal.enTransaction !== "Awaiting")
          .map((withdrawal) => ({
            Transfers: withdrawal.id, // ID da transferência
            Seller: withdrawal.pixKey || "Chave não informada", // Substitua pelo campo correto do backend
            Value: withdrawal.value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            Status: withdrawal.enTransaction,
          }));

        setData(filteredData);
        setTotalRecords(result.totalCount);
      } else {
        console.error("Formato inesperado da resposta da API:", result);
      }
    } catch (error) {
      console.error("Erro ao buscar transferências:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWithdrawals(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm, fetchWithdrawals]);

  const columns = [
    { field: "Transfers", header: "ID" },
    { field: "Seller", header: "Chave Pix" },
    { field: "Value", header: "Valor" },
    { field: "Status", header: "Status" },
  ];

  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
  };

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  // Função chamada ao clicar em "Ver mais"
  const handleInfoButtonClick = (rowData) => {
    alert(`Ver mais sobre a transferência: ${rowData.Transfers}`);
    // No futuro, você pode usar isso para abrir um modal ou navegar para outra página
  };

  return (
    <div>
      <h1 className="dash-title">Histórico de transferências</h1>
      <div className="table-section">
        <CustomDataTable
          data={data}
          columns={columns}
          title="Transferências"
          loading={loading}
          totalRecords={totalRecords}
          rows={pageSize}
          page={page}
          onPageChange={onPageChange}
          onSearch={onSearch}
          searchPlaceholder="Buscar por transferências ou sellers"
          showInfoColumn={true}
          infoButtonTitle="Ver mais"
          onInfoButtonClick={handleInfoButtonClick}
          showWhatsappButton={false} // Não exibe o botão de WhatsApp
        />
      </div>
    </div>
  );
};

export default AdminTransfers;
