import React, { useState, useEffect, useCallback } from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import BalanceStatistics from "components/DashboardComponents/BalanceStatistics/BalanceStatistics";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import ModalBalance from "components/DashboardComponents/ModalBalance/ModalBalance";
import BasicButton from "components/BasicButton/BasicButton";
import { ReactComponent as Chargeback } from "assets/icons/cancel.svg";
import { ReactComponent as Reembolsado } from "assets/icons/arrow.svg";
import { getUserBalance, getUserSummary, getWithdrawals } from "services/authService";
import "./MerchantBalance.css";

function MerchantBalance() {
  const [balanceData, setBalanceData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]); // Dados da tabela
  const [page, setPage] = useState(0); // Página atual
  const [pageSize, setPageSize] = useState(10); // Tamanho da página
  const [totalRecords, setTotalRecords] = useState(0); // Total de registros
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [searchTerm, setSearchTerm] = useState(""); // Termo de busca
  const userId = localStorage.getItem("userId");

  // Função para buscar o histórico de saques
  const fetchWithdrawals = useCallback(async (page, pageSize, searchTerm = "") => {
    setLoading(true);
    try {
      const result = await getWithdrawals({
        UserId: userId,
        page: page + 1, // O backend usa base 1
        pageSize,
        Term: searchTerm,
      });

      if (result && result.items && result.totalCount !== undefined) {
        setTableData(
          result.items.map((withdrawal) => ({
            value: withdrawal.value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            date: new Date(withdrawal.createdAt).toLocaleDateString("pt-BR"),
            status: withdrawal.enTransaction,
            method: withdrawal.enPaymentSystem,
          }))
        );
        setTotalRecords(result.totalCount);
      } else {
        console.error("Formato inesperado da resposta da API:", result);
      }
    } catch (error) {
      console.error("Erro ao buscar histórico de saques:", error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchWithdrawals(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm, fetchWithdrawals]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (!userId) {
          throw new Error("User ID não encontrado.");
        }

        const [summary, balance] = await Promise.all([
          getUserSummary(userId),
          getUserBalance(userId),
        ]);

        setSummaryData(summary);
        setBalanceData(balance);
      } catch (error) {
        console.error("Erro ao buscar dados de resumo e saldo:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const releasedBalance = {
    pix: balanceData?.accBalance || 0,
    creditCard: balanceData?.accBalanceCredit || 0,
    boleto: balanceData?.accBalanceBoleto || 0,
  };

  const awaitingBalance = {
    pix: balanceData?.accBalanceAwaiting || 0,
    creditCard: balanceData?.accBalanceCreditAwaiting || 0,
    boleto: balanceData?.accBalanceBoletoAwaiting || 0,
  };

  const salesData = [
    { icon: <Chargeback />, label: "Chargeback", value: summaryData?.chargebacks || 0 },
    { icon: <Reembolsado />, label: "Reembolsos", value: summaryData?.refunds || 0 },
    { icon: <Chargeback />, label: "Cancelado", value: summaryData?.cancellations || 0 },
  ];

  const columns = [
    { field: "value", header: "Valor" },
    { field: "date", header: "Data" },
    { field: "status", header: "Status" },
    { field: "method", header: "Método de Pagamento" },
  ];

  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
  };

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchWithdrawals(page, pageSize, searchTerm); // Recarrega os dados após fechar o modal
  };

  return (
    <div className="home-dashboard">
      <div className="dash-container">
        <h1 className="dash-title">Transferências</h1>
        <div className="dash-content-1">
          <BasicButton
            icon="pi pi-dollar"
            label="Solicitar saque"
            onClick={handleOpenModal}
            className="login-button"
          />
        </div>
      </div>
      <div className="merchant-grids">
        <div className="merchant-content-1">
          <div className="merchant-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Saldo disponível"
                  salesData={releasedBalance}
                  salesColor="black"
                  totalSoldText="Valor disponível"
                  salesMethodText="Métodos de pagamento selecionados"
                />
                <SalesOverviewCard
                  title="Saldo Pendente"
                  salesData={awaitingBalance}
                  salesColor="black"
                  totalSoldText="Valor Pendente"
                  salesMethodText="Selecione para ver total líquido por método de pagamento"
                />
              </div>
            </div>
            <div className="table-section">
              <CustomDataTable
                data={tableData}
                columns={columns}
                title="Histórico de saques"
                loading={loading}
                totalRecords={totalRecords}
                rows={pageSize}
                page={page}
                onPageChange={onPageChange}
                onSearch={onSearch}
                searchPlaceholder="Buscar por valor, data ou status"
                showInfoColumn={false}
              />
            </div>
          </div>
          <div className="merchant-grid-2">
            <BalanceStatistics title="Estatísticas Tristes" salesData={salesData} />
          </div>
        </div>
      </div>
      <ModalBalance
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        saldoPix={releasedBalance.pix}
        saldoCartao={releasedBalance.creditCard}
        userId={userId}
      />
    </div>
  );
}

export default MerchantBalance;
