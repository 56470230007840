import React, { useState } from "react";
import Modal from "react-modal";
import { SelectButton } from "primereact/selectbutton";
import { requestCashOut } from "services/authService"; // Importando a função
import "./ModalBalance.css";

Modal.setAppElement("#root");

const ModalBalance = ({ isOpen, closeModal, saldoPix, saldoCartao, userId }) => {
  const options = ["Pix/Boleto", "Cartão de Crédito"];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [valorSaque, setValorSaque] = useState("R$ 0,00");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatToBRL = (value) => {
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value || 0);
    return formattedValue;
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, ""); // Remove caracteres não numéricos
    const floatValue = parseFloat(value) / 100;
    setValorSaque(formatToBRL(floatValue));
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.value);
    setValorSaque("R$ 0,00");
  };

  const handleSaque = async () => {
    const enPaymentSystem = selectedOption === "Pix/Boleto" ? 1 : 2;
  
    const amount = parseFloat(
      valorSaque
        .replace(/[^\d,]/g, "") // Remove caracteres não numéricos, mas mantém vírgula
        .replace(".", "")        // Remove separadores de milhar
        .replace(",", ".")       // Troca separador decimal
    );
  
    if (!userId) {
      setError("Usuário não identificado para solicitar saque.");
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      await requestCashOut(userId, amount, enPaymentSystem);
  
      alert(`Saque de ${formatToBRL(amount)} solicitado com sucesso!`);
      closeModal();
      window.location.reload(); // Recarrega a página após fechar o modal
    } catch (err) {
      console.error(err);
      setError(err.message || "Erro ao solicitar saque. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const saldoDisponivel = selectedOption === "Pix/Boleto" ? saldoPix : saldoCartao;
  const isDisabled = valorSaque === "R$ 0,00" || valorSaque === "";

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div className="modal-header">
        <h2>Solicitar saque</h2>
      </div>

      <div className="modal-body">
        <div className="select-option">
          <SelectButton
            value={selectedOption}
            onChange={handleOptionChange}
            options={options}
          />
        </div>

        <div className="saque-section">
          <p>Saldo disponível: {formatToBRL(saldoDisponivel)}</p>
          <input
            type="text"
            placeholder="Digite o valor do saque"
            value={valorSaque}
            onChange={handleInputChange}
          />
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>

      <div className="modal-footer">
        <button onClick={closeModal} className="cancel-btn">
          Cancelar
        </button>
        <button
          onClick={handleSaque}
          className="sacar-btn"
          disabled={isDisabled || loading}
        >
          {loading ? "Solicitando..." : "Solicitar"}
        </button>
      </div>
    </Modal>
  );
};

export default ModalBalance;
